import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { apiClient } from '../../Interceptor'
import { TUpdateDailyGrowingPlanData, TDailyOrderData } from './types'
import { QuantityReportsQueryKeys } from '../QuantityReports/queries'

enum QueryKeys {
  OrdersDaily = 'OrdersDaily',
}

async function ordersDaily(dailyOrderId?: string): Promise<TDailyOrderData> {
  try {
    const response = await apiClient.get(`/app/orders/daily/${dailyOrderId}`)
    return response.data
  } catch (e: any) {
    throw new Error(e)
  }
}

export const useOrdersDailyQuery = (dailyOrderId?: string) => {
  const key = [QueryKeys.OrdersDaily, dailyOrderId]

  return useQuery<TDailyOrderData, Error>(
    [key],
    () => ordersDaily(dailyOrderId),
    {
      enabled: !!dailyOrderId,
      cacheTime: 0
    }
  )
}

type TUpdateGrowingPlanMutationParams = {
  dailyOrderId: string,
  weeklyOrderId: string,
  data: TUpdateDailyGrowingPlanData
}

async function updateDailyOrder(
  { dailyOrderId, data }: TUpdateGrowingPlanMutationParams
): Promise<void> {
  try {
    await apiClient.put(`/app/orders/daily/${dailyOrderId}`, data)
  } catch (e: any) {
    throw new Error(e)
  }
}

export const useUpdateDailyOrderMutation = () => {
  const queryClient = useQueryClient()

  return useMutation<void, Error, TUpdateGrowingPlanMutationParams>(updateDailyOrder, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(
        [QuantityReportsQueryKeys.OrdersCalendar]
      )
    }
  })
}

async function confirmDailyOrder(dailyOrderId: string): Promise<void> {
  try {
    await apiClient.put(`/app/orders/daily/${dailyOrderId}/confirm`)
  } catch (e: any) {
    throw new Error(e)
  }
}

export const useConfirmDailyOrderMutation = () => {
  const queryClient = useQueryClient()

  return useMutation<void, Error, string>(confirmDailyOrder, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(
        [QuantityReportsQueryKeys.OrdersCalendar]
      )
    }
  })
}

async function createDailyOrder(data: TUpdateDailyGrowingPlanData): Promise<void> {
  try {
    await apiClient.post('/app/orders/daily', data)
  } catch (e: any) {
    throw new Error(e)
  }
}

export const useCreateDailyOrderMutation = () => {
  const queryClient = useQueryClient()

  return useMutation<void, Error, TUpdateDailyGrowingPlanData>(createDailyOrder, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(
        [QuantityReportsQueryKeys.OrdersCalendar]
      )
    }
  })
}

type TCheckDailyOrderTimeMutationParams = {
  date: string,
  dailyOrderId: string,
  weeklyOrderId: string
}
