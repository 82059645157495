import { FC, memo } from 'react'
import styles from './WeeklyDailyHeader.module.scss'

type TProps = {
  title: string
  description: string
}
const WeeklyDailyHeader: FC<TProps> = ({ title, description }) => (
  <div className={styles.wrapper}>
    <div>{title}</div>
    <div className={styles.description}>{description}</div>
  </div>
)

export default memo(WeeklyDailyHeader)
