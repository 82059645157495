import { isAfter, isWithinInterval, subWeeks } from 'date-fns'
import { Event } from 'react-big-calendar'
import { TDailyToWeeklyEvent, TOrdersCalendarDataItem } from '../types'
import {
  dateFromDotDateFormat,
  dotDateFormat,
  endOfWeekCustom,
  startOfWeekCustom,
  weekDays
} from '../../../../shared/utils/date'
import ReviewWeeklyReport from '../components/events/ReviewWeeklyReport/ReviewWeeklyReport'
import AddCopyWeeklyReport from '../components/events/AddCopyWeeklyReport/AddCopyWeeklyReport'
import styles from '../components/Calendar/Calendar.module.scss'
import QuantityDetails from '../components/events/QuantityDetails/QuantityDetails'
import EditQuantity from '../components/events/EditQuantity/EditQuantity'
import AddQuantity from '../components/events/AddQuantity/AddQuantity'
import EditWeeklyDraft from '../components/events/EditWeeklyDraft/EditWeeklyDraft'
import ReadOnlyWeeklyReportButton from '../components/events/ReadOnlyWeeklyReportButton/ReadOnlyWeeklyReportButton'

export function isWeeklyItem({ orderType }: TOrdersCalendarDataItem) {
  return orderType === 'WEEKLY'
}

export function isNegotiatingItem({ status }: TOrdersCalendarDataItem) {
  return status === 'NEGOTIATING'
}

export function isDraftItem({ status }: TOrdersCalendarDataItem) {
  return status === 'DRAFT'
}
export function isConfirmedItem({ status }: TOrdersCalendarDataItem) {
  return status === 'CONFIRMED'
}

export function isDailyItem({ orderType }: TOrdersCalendarDataItem) {
  return orderType === 'DAILY'
}

function eventReview(item: TOrdersCalendarDataItem): Event {
  return {
    start: dateFromDotDateFormat(item.startDate),
    end: endOfWeekCustom(dateFromDotDateFormat(item.endDate)),
    title: <ReviewWeeklyReport item={item} />
  }
}

function eventDraft(item: TOrdersCalendarDataItem): Event {
  return {
    start: dateFromDotDateFormat(item.startDate),
    end: endOfWeekCustom(dateFromDotDateFormat(item.endDate)),
    title: <EditWeeklyDraft item={item} />
  }
}

function eventReadOnly(weekStart: Date, weekEnd: Date, weeklyOrderId: string): Event {
  return {
    start: weekStart,
    allDay: true,
    end: weekEnd,
    title: <ReadOnlyWeeklyReportButton weeklyOrderId={weeklyOrderId}/>
  }
}

function eventAddCopy(
  weekStartDate: Date
): Event {
  return {
    start: weekStartDate,
    end: endOfWeekCustom(weekStartDate),
    title: <AddCopyWeeklyReport weekStartDate={weekStartDate}/>
  }
}

export function weeklyEvents(
  items: TOrdersCalendarDataItem[],
  weeksStartDates: Date[],
  allowToCreate: boolean
) {
  const negotiatingWeeklyItem = (weekStartDate: Date) => items
    .filter(isWeeklyItem)
    .filter(isNegotiatingItem)
    .find(({ startDate }) => startDate === dotDateFormat(weekStartDate))

  const draftWeeklyItem = (weekStartDate: Date) => items
    .filter(isWeeklyItem)
    .filter(isDraftItem)
    .find(({ startDate }) => startDate === dotDateFormat(weekStartDate))

  return weeksStartDates.reduce<Event[]>((result, weekStartDate) => {
    const foundItemNegotiate = negotiatingWeeklyItem(weekStartDate)
    const foundItemDraft = draftWeeklyItem(weekStartDate)
    const isConfirmedWeek = !!confirmedWeek(items, weekStartDate)
    const endOfPreviousWeek = endOfWeekCustom(subWeeks(new Date(), 1))
    return [
      ...result,
      ...(
        foundItemNegotiate
          ? [eventReview(foundItemNegotiate)]
          : foundItemDraft ? [eventDraft(foundItemDraft)]
            : isAfter(weekStartDate, endOfPreviousWeek) && allowToCreate && !isConfirmedWeek
              ? [eventAddCopy(weekStartDate)]
              : []
      )
    ]
  },
  [])
}

export function eventDaily(
  date: Date,
  weeklyItem: TOrdersCalendarDataItem,
  item?: TOrdersCalendarDataItem

): Event {
  return {
    end: date,
    start: date,
    title: (
      <div className={styles.buttonsWrapper}>
        {item?.dailyOrderId && item && <QuantityDetails item={item}/>}
        {item && item?.dailyOrderId && item.editableForCurrentUser
          && <EditQuantity item={item}/>}
        { item && !item.dailyOrderId && item.canAddDailyOrder
          && <AddQuantity weeklyItem={weeklyItem} date={date}/>}
      </div>
    )
  }
}

export function dailyItems(items: TOrdersCalendarDataItem[]) {
  return items.filter(isDailyItem)
}

export function weeklyConfirmedItems(items: TOrdersCalendarDataItem[]) {
  return items
    .filter(isWeeklyItem)
    .filter(isConfirmedItem)
}

const confirmedWeek = (items: TOrdersCalendarDataItem[], date: Date) =>
  weeklyConfirmedItems(items).find(
    ({ startDate }) => weekDays(dateFromDotDateFormat(startDate))
      .map((weekDate) => dotDateFormat(weekDate))
      .includes(dotDateFormat(date))
  )

export function dailyEvents(
  items: TOrdersCalendarDataItem[],
  calendarDates: Date[]
) {
  const dailyItemByDate = (date: Date) =>
    dailyItems(items).find(({ startDate }) => startDate === dotDateFormat(date))

  return calendarDates.reduce<Event[]>(
    (result, date) => {
      const foundConfirmedWeek = confirmedWeek(items, date)

      return (
        [
          ...result,
          ...(
            foundConfirmedWeek
              ? [eventDaily(date, foundConfirmedWeek, dailyItemByDate(date))]
              : []
          )
        ]
      )
    },
    []
  )
}

export function dailyToWeeklyEvents(items: TOrdersCalendarDataItem[]) {
  const dailyItemByDate = dailyItems(items)

  const weeklyEvents = dailyItemByDate.reduce<Record<string, TDailyToWeeklyEvent>>(
    (acc, { startDate, weeklyOrderId }) => {
      const weekStart = startOfWeekCustom(dateFromDotDateFormat(startDate))
      const weekEnd = endOfWeekCustom(dateFromDotDateFormat(startDate))

      if (!acc[weeklyOrderId]) {
        acc[weeklyOrderId] = { weekStart, weekEnd }
      }
      return acc
    }, {}
  )
  return Object.entries(weeklyEvents).map(([key, { weekEnd, weekStart }]) => (
    eventReadOnly(weekStart, weekEnd, key)
  ))
}

export function preparedDailyEvents(dailyEvents: Event[], weeklyEvents: Event[]) {
  return dailyEvents
    .filter(({ start: dailyStart }) => (
      !weeklyEvents.some(
        ({ start, end }) =>
          start
          && end
          && dailyStart
          && isWithinInterval(dailyStart, { start, end })
      )
    ))
}
