import { useSearchParams } from 'react-router-dom'
import { useReportIdParam } from './useReportIdParam'

export function useIsEditMode() {
  return !!useReportIdParam()
}

export function useIsDraft() {
  const [searchParams] = useSearchParams()
  return !!searchParams.get('draft')
}
