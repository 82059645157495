import { TWeeklyOrderData } from '../../WeeklyReport/types'
import { TProductModel } from '../types'

export function useDisabledProductModels(
  weeklyOrdersData?: TWeeklyOrderData
): TProductModel[] | undefined {
  return weeklyOrdersData?.weeklyOrderItems.map(({ label, product }) => ({
    labelId: label?.id,
    productId: product?.id
  }))
}
