import { BrowserRouter, Navigate, Route, Routes as RouterRoutes } from 'react-router-dom'
import React from 'react'
import { NotFound } from './features/notFound'
import { MainLayout } from './layouts/mainLayout'
import { Login } from './features/auth/login'
import { ResetPassword, ForgotPassword } from './features/auth/forgotPassword'
import { SignUp } from './features/auth/signUp'
import { AuthGuard, useAuthContext } from './features/auth/authBase'
import { AccountDetails } from './features/account/accountDetails'
import { AccountEdit } from './features/account/accountEdit'
import { Logout } from './features/auth/logout'
import { GrowingPlanList } from './features/growingPlan/ListGrowingPlan'
import { GrowingPlanCreate } from './features/growingPlan/CreateGrowingPlan'
import { UpdateGrowingPlan } from './features/growingPlan/UpdateGrowingPlan'
import { CreateCropStock } from './features/cropStock/CreateCropStock'
import { CropStockList } from './features/cropStock/ListCropStock'
import { WeeklyReport } from './features/quantityReports/WeeklyReport'
import { QuantityReports } from './features/quantityReports/QuantityReports'
import { CreateInnerCircle } from './features/innerCircle/CreateInnerCircle'
import { EditInnerCircle } from './features/innerCircle/EditInnerCircle'
import { InnerCircleList } from './features/innerCircle/InnerCircleList'
import { DailyOrder } from './features/quantityReports/DailyOrder'
import { Offer } from './features/buy/Offer'
import { Dashboard } from './features/analytics/dashboard'
import { BidOffer } from './features/buy/BidOffer'
import { ListOffer } from './features/sell/ListOffer'
import { EditOffer } from './features/sell/EditOffer'
import { Interceptor } from './features/Interceptor'
import { Offers } from './features/buy/Offers'
import { MySells } from './features/mySells'
import { MyOrders } from './features/myOrders'
import { CreateOffer } from './features/sell/CreateOffer'
import { WeeklyReportsAnalytics } from './features/analytics/weeklyReportsAnalytics'
import PlanningForecast from './features/analytics/planningForecast/components/PlanningForecast/PlanningForecast'
import { Settings } from './features/settings'
import { AnalyticsBoard, PlanningBoard, TradingBoard } from './features/navigationBoard'
import { Users } from './features/admin/users'
import { Products } from './features/admin/products'
import { Labels } from './features/admin/labels'
import NotFoundGuard from './features/notFound/state/NotFoundState'
import RoleGuard from './features/auth/authBase/RoleGuard'
import { EmailConfirmation } from './features/emailConfirmation'

const Routes = () => {
  const { authData } = useAuthContext()
  const role = authData?.user?.role
  const isAdmin = role === 'ADMIN'

  return (
    <BrowserRouter>
      <Interceptor/>
      <RouterRoutes>

        <Route
          path="/"
          element={(
            <AuthGuard>
              <NotFoundGuard>
                <MainLayout />
              </NotFoundGuard>
            </AuthGuard>
          )}>

          <Route index element={<Navigate to={isAdmin ? '/admin/users' : '/dashboard'} />} />
          <Route path="admin">
            <Route path="users" element={<RoleGuard role="ADMIN"><Users/></RoleGuard>}/>
            <Route
              path="products"
              element={(
                <RoleGuard role="ADMIN">
                  <Products/>
                </RoleGuard>
              )}/>
            <Route path="labels" element={<RoleGuard role="ADMIN"><Labels/></RoleGuard>}/>
            <Route path="products" element={<RoleGuard role="ADMIN"><></></RoleGuard>}/>
            <Route path="labels" element={<RoleGuard role="ADMIN"><></></RoleGuard>}/>
          </Route>

          <Route path="/analytics" element={<RoleGuard role="USER"><AnalyticsBoard/></RoleGuard>} />
          <Route path="/trading" element={<RoleGuard role="USER"><TradingBoard/></RoleGuard>} />
          <Route path="/planning" element={<RoleGuard role="USER"><PlanningBoard/></RoleGuard>} />

          <Route path="dashboard">
            <Route index element={<RoleGuard role="USER"><Dashboard /></RoleGuard>}/>
            <Route path="weeklyReports" element={<WeeklyReportsAnalytics/>}/>
            <Route path="planning-forecast" element={<PlanningForecast/>}/>
          </Route>
          <Route path="settings" element={<RoleGuard role="USER"><Settings/></RoleGuard>}/>
          <Route path="my-account" element={<RoleGuard role="USER"><AccountDetails /></RoleGuard>} />
          <Route path="edit-account" element={<RoleGuard role="USER"><AccountEdit/></RoleGuard>} />
          <Route path="growing-plan" element={<RoleGuard role="USER"><GrowingPlanList/></RoleGuard>} />
          <Route path="growing-plan/create" element={<RoleGuard role="USER"><GrowingPlanCreate/></RoleGuard>}/>
          <Route path="growing-plan/update/:growingPlanId" element={<RoleGuard role="USER"><UpdateGrowingPlan/></RoleGuard>}/>
          <Route path="crop-stock" element={<RoleGuard role="USER"><CropStockList/></RoleGuard>}/>
          <Route path="crop-stock/create" element={<RoleGuard role="USER"><CreateCropStock/></RoleGuard>}/>

          <Route path="quantity-reports">
            <Route index element={<RoleGuard role="USER"><QuantityReports /></RoleGuard>} />
            <Route path="weekly">
              <Route index element={<RoleGuard role="USER"><Navigate to="create" /></RoleGuard>} />
              <Route path="create" element={<RoleGuard role="USER"><WeeklyReport/></RoleGuard>} />
              <Route path=":reportId" element={<RoleGuard role="USER"><WeeklyReport/></RoleGuard>} />
            </Route>

            <Route path="daily">
              <Route
                index
                element={(
                  <RoleGuard role="USER">
                    <Navigate to="create" />
                  </RoleGuard>
                )} />
              <Route path="create" element={<RoleGuard role="USER"><DailyOrder/></RoleGuard>} />
              <Route path=":reportId" element={<RoleGuard role="USER"><DailyOrder/></RoleGuard>} />
            </Route>
          </Route>
          <Route path="inner-circle">
            <Route index element={<RoleGuard role="USER"><InnerCircleList/></RoleGuard>}/>
            <Route path="create" element={<RoleGuard role="USER"><CreateInnerCircle/></RoleGuard>}/>
            <Route path="edit/:innerCircleId" element={<RoleGuard role="USER"><EditInnerCircle/></RoleGuard>}/>
          </Route>
          <Route path="buy">
            <Route path=":productId/bid-offer" element={<RoleGuard role="USER"><BidOffer/></RoleGuard>}/>
          </Route>
          <Route path="sell">
            <Route index element={<RoleGuard role="USER"><ListOffer/></RoleGuard>}/>
            <Route path="create" element={<RoleGuard role="USER"><CreateOffer/></RoleGuard>}/>
            <Route path="edit/:offerId" element={<RoleGuard role="USER"><EditOffer/></RoleGuard>}/>
          </Route>
          <Route path="my-sells" element={<RoleGuard role="USER"><MySells/></RoleGuard>}/>
          <Route path="my-orders" element={<RoleGuard role="USER"><MyOrders/></RoleGuard>}/>
        </Route>

        <Route path="buy" element={<MainLayout isNonAuth/>}>
          <Route index element={<Offers/>}/>
          <Route path=":productId" element={<Offer/>}/>
        </Route>

        <Route path="/" element={<MainLayout/>}>
          <Route path="login" element={<Login />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="reset-password" element={<ResetPassword />}/>
          <Route path="sign-up" element={<SignUp />}/>
          <Route path="logout" element={<Logout />} />
          <Route path="email-confirm" element={<EmailConfirmation/>}/>
        </Route>

        <Route path="*" element={<NotFound />}/>
      </RouterRoutes>
    </BrowserRouter>
  )
}
export default Routes
