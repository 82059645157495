import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import styles from './InActiveAccordionSummary.module.scss'
import { useLabelListQuery, useProductListQuery } from '../../../../growingPlan/BaseGrowingPlanWorkShop/queries'
import { useBaseQuantityFormContext } from '../../state/BaseQuantityFormState'
import QuantityRemove from '../QuantityRemove/QuantityRemove'

function useProductName(quantityIndex: number) {
  const { formReturn: { watch } } = useBaseQuantityFormContext()
  const { data } = useProductListQuery()
  const productId = watch(`orderItems[${quantityIndex}].productId`)
  return useMemo(() => {
    if (!data || !productId) {
      return undefined
    }
    return data?.find(({ id }) => id === productId)?.name
  }, [productId, data])
}

function useLabelName(quantityIndex: number) {
  const { data: labelsList } = useLabelListQuery()
  const { formReturn: { watch } } = useBaseQuantityFormContext()
  const { data } = useProductListQuery()
  const labelId = watch(`orderItems[${quantityIndex}].labelId`)

  return useMemo(() => {
    if (!labelsList) {
      return undefined
    }
    return labelsList?.find(({ id }) => labelId === id)?.name
  },
  [labelId, data, labelsList])
}

type TProps = {
  quantityIndex: number
  isQuantities: boolean
  icon: JSX.Element
}

const InActiveAccordionSummary: FC<TProps> = ({ quantityIndex, isQuantities, icon }) => {
  const { t } = useTranslation()
  const productName = useProductName(quantityIndex)
  const labelName = useLabelName(quantityIndex)

  const { formReturn: { formState: { errors } } } = useBaseQuantityFormContext()
  const errorFields = (errors as any)?.orderItems?.[quantityIndex]
  return (
    <div className={classNames(styles.wrapper, { [styles.quantities]: isQuantities })}>
      <div className={styles.content}>
        <div>
          {icon}
        </div>
        <h1 className={classNames(
          styles.productName,
          {
            [styles.empty]: !productName,
            [styles.error]: !!errorFields?.productId
          }
        )}>
          {productName || t('quantity.productNotSelected')}
        </h1>
        <div className={styles.circle}/>
        <p className={classNames(
          styles.label,
          {
            [styles.empty]: !labelName,
            [styles.error]: !!errorFields?.label
          }
        )}>
          {labelName || t('quantity.labelNotSelected')}
        </p>
      </div>
      {isQuantities && <QuantityRemove quantityIndex={quantityIndex}/>}
    </div>
  )
}

export default InActiveAccordionSummary
