import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { FC } from 'react'
import styles from './ReadOnlyWeeklyReportButton.module.scss'
import SecondaryButton from '../../../../../../shared/components/buttons/SecondaryButton/SecondaryButton'
import { useQuantityReportsContext } from '../../../state/QuantityReportsState'

type TProps = {
  weeklyOrderId: string
}
const ReadOnlyWeeklyReportButton: FC<TProps> = ({ weeklyOrderId }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { formMethods: { getValues } } = useQuantityReportsContext()
  const handleNavigate = () => {
    const { buyer, seller } = getValues()
    navigate({
      pathname: `weekly/${weeklyOrderId}`,
      search: `&readOnly=true&buyerId=${buyer}&sellerId=${seller}`
    })
  }
  return (
    <SecondaryButton
      text={t('quantity.calendar.showWeeklyReport')}
      onClick={handleNavigate}
      className={styles.buttonText}
    />
  )
}

export default ReadOnlyWeeklyReportButton
