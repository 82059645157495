import { Control } from 'react-hook-form'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './ActiveAccordionSummary.module.scss'
import QuantityRemove from '../QuantityRemove/QuantityRemove'
import { useBaseQuantityFormContext } from '../../state/BaseQuantityFormState'
import ProductSelect from '../quantityFields/productSelect/ProductSelect'
import CustomerUnitSelect from '../quantityFields/customerUnitSelect/CustomerUnitSelect'
import TradeInSelect from '../quantityFields/tradeInSelect/TradeInSelect'
import LogisticSelect from '../quantityFields/logisticSelect/LogisticSelect'
import LabelSelect from '../quantityFields/labelSelect/LabelSelect'
import AmountInput from '../quantityFields/amountInput/AmountInput'
import { useGetUnit } from '../../hooks/useGetUnit'
import { useValidationRules } from '../../../../../shared/hooks/useValidationRules'

type TProps = {
  control: Control<any>
  icon: JSX.Element
  quantityIndex: number
  isQuantities: boolean
}
const ActiveAccordionSummary: FC<TProps> = ({ control, quantityIndex, isQuantities, icon }) => {
  const { t } = useTranslation()
  const {
    isSeller,
    onlyDay,
    readOnly,
    disabledProductModels,
    formReturn: { getValues }
  } = useBaseQuantityFormContext()
  const { requiredRule } = useValidationRules()
  const unit = useGetUnit(quantityIndex)
  const isDisabledProduct = useMemo(() => {
    const formData = getValues()
    const { productId, labelId } = formData.orderItems[quantityIndex]
    return !!disabledProductModels
      ?.find(({ productId: modelProductId, labelId: modelLabelId }) => (
        modelProductId === productId && labelId === modelLabelId
      ))
  }, [])
  const isControlsDisabled = (!isSeller || !!onlyDay || !!readOnly) && isDisabledProduct

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div className={styles.iconWrap}>
          {icon}
        </div>
        <div className={styles.formField}>
          <ProductSelect
            disabled={isControlsDisabled}
            quantityIndex={quantityIndex}
            control={control}/>
          <CustomerUnitSelect
            disabled={isControlsDisabled}
            quantityIndex={quantityIndex}
            control={control}/>
          <TradeInSelect
            disabled={isControlsDisabled}
            quantityIndex={quantityIndex}
            control={control}/>
          <LogisticSelect
            disabled={isControlsDisabled}
            quantityIndex={quantityIndex}
            control={control}/>
          <LabelSelect
            disabled={isControlsDisabled}
            quantityIndex={quantityIndex}
            control={control}/>
          <div>
            <AmountInput
              disabled
              quantityIndex={quantityIndex}
              control={control}
              name="amountPerCu"
              label={`${t('quantity.inputLabelTest.amountCU', { unit })}`}/>
          </div>
          <div>
            <AmountInput
              disabled={isControlsDisabled}
              quantityIndex={quantityIndex}
              control={control}
              name="amountPerTU"
              rules={{ required: requiredRule() }}
              label={`${t('quantity.inputLabel.amountTU')} *`}/>
          </div>
          <div>
            <AmountInput
              disabled={isControlsDisabled}
              quantityIndex={quantityIndex}
              control={control}
              rules={{ required: requiredRule() }}
              name="amountPerLu"
              label={`${t('quantity.inputLabel.amountLU')} *`}/>
          </div>
        </div>
      </div>
      {isQuantities && <QuantityRemove quantityIndex={quantityIndex}/>}
    </div>
  )
}

export default ActiveAccordionSummary
