import { UseFormReturn } from 'react-hook-form'
import { useMemo } from 'react'
import isEqual from 'lodash/isEqual'
import { useIsEditMode, useIsDraft } from './editModeHooks'

export function useControlsDisableState(
  initialFormData: any,
  { watch, formState: { isValid } }: UseFormReturn<any>,
  editableForCurrentUser?: boolean
) {
  const formData = watch()
  const hasFormInitialValue = useMemo(() => isEqual(initialFormData, formData), [formData])
  const isEditMode = useIsEditMode()
  const isDraft = useIsDraft()
  return {
    isSubmitDisabled: isEditMode && !isDraft && (!editableForCurrentUser || hasFormInitialValue),
    isConfirmDisabled:
      isEditMode
      && (!editableForCurrentUser || !hasFormInitialValue),
    isSaveForLaterDisabled: editableForCurrentUser === undefined ? false : !editableForCurrentUser
  }
}
