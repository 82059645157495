import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useSearchParams } from 'react-router-dom'
import { apiClient } from '../../Interceptor'
import { TUpdateWeeklyGrowingPlanData, TWeeklyInitParams, TWeeklyInitResponse, TWeeklyOrderData } from './types'
import { QuantityReportsQueryKeys } from '../QuantityReports/queries'
import { NO_GROWING_PLAN_OPTION } from '../BaseQuantityReports'

enum QueryKeys{
  OrdersWeekly = 'OrdersWeekly',
  OrdersWeeklyCopy = 'OrdersWeeklyCopy',
  WeeklyInit = 'WeeklyInit'
}

async function ordersWeekly(weeklyOrderId?: string): Promise<TWeeklyOrderData> {
  try {
    const response = await apiClient.get(`/app/orders/weekly/${weeklyOrderId}`)
    return response.data
  } catch (e: any) {
    throw new Error(e)
  }
}

export const useOrdersWeeklyQuery = (weeklyOrderId?: string) => {
  const key = [QueryKeys.OrdersWeekly, weeklyOrderId]

  return useQuery<TWeeklyOrderData, Error>(
    [key],
    () => ordersWeekly(weeklyOrderId),
    {
      enabled: !!weeklyOrderId,
      cacheTime: 0
    }
  )
}

async function copyPreviousWeeklyReport(
  data: {
    firstWeekDate: string,
    growingPlanId?: string | null,
    buyerId: string | null,
    sellerId: string | null
  }
): Promise<TWeeklyOrderData> {
  try {
    const response = await apiClient('/app/orders/weekly/clone-previous', { params: data })
    return response.data
  } catch (e: any) {
    throw new Error(e)
  }
}

export const useCopyPreviousWeeklyReportQuery = () => {
  const [searchParams] = useSearchParams()

  const firstWeekDate = searchParams.get('date')
  const growingPlanId = searchParams.get('growingPlanId')
  const sellerId = searchParams.get('sellerId')
  const buyerId = searchParams.get('buyerId')
  const isCopy = searchParams.get('copy')
  const prepareGrowingPlan = growingPlanId === NO_GROWING_PLAN_OPTION ? undefined : growingPlanId
  return useQuery<TWeeklyOrderData, Error >(
    [QueryKeys.OrdersWeeklyCopy,
      firstWeekDate,
      growingPlanId,
      isCopy,
      buyerId,
      sellerId
    ],
    () => copyPreviousWeeklyReport({
      firstWeekDate: firstWeekDate!,
      growingPlanId: prepareGrowingPlan,
      buyerId,
      sellerId
    }), {
      enabled: !!isCopy && !!firstWeekDate && (!!prepareGrowingPlan || !!sellerId || !!buyerId)
    }
  )
}

type TUpdateGrowingPlanMutationParams = {
  weeklyOrderId: string,
  data: TUpdateWeeklyGrowingPlanData
}

async function updateWeeklyOrder(
  { weeklyOrderId, data }: TUpdateGrowingPlanMutationParams
): Promise<void> {
  try {
    await apiClient.put(`/app/orders/weekly/${weeklyOrderId}`, data)
  } catch (e: any) {
    throw new Error(e)
  }
}

export const useUpdateWeeklyOrderMutation = () => {
  const queryClient = useQueryClient()

  return useMutation<void, Error, TUpdateGrowingPlanMutationParams>(updateWeeklyOrder, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(
        [QuantityReportsQueryKeys.OrdersCalendar]
      )
    }
  })
}

async function confirmWeeklyOrder(weeklyOrderId: string): Promise<void> {
  try {
    await apiClient.put(`/app/orders/weekly/${weeklyOrderId}/confirm`)
  } catch (e: any) {
    throw new Error(e)
  }
}

export const useConfirmWeeklyOrderMutation = () => {
  const queryClient = useQueryClient()

  return useMutation<void, Error, string>(confirmWeeklyOrder, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(
        [QuantityReportsQueryKeys.OrdersCalendar]
      )
    }
  })
}

async function createWeeklyOrder(data: TUpdateWeeklyGrowingPlanData): Promise<void> {
  const { publish, ...rest } = data
  try {
    await apiClient.post(`/app/orders/weekly?publish=${publish}`, rest)
  } catch (e: any) {
    throw new Error(e)
  }
}

export const useCreateWeeklyOrderMutation = () => {
  const queryClient = useQueryClient()

  return useMutation<void, Error, TUpdateWeeklyGrowingPlanData>(createWeeklyOrder, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(
        [QuantityReportsQueryKeys.OrdersCalendar]
      )
    }
  })
}

async function saveDraftWeeklyOrder(
  { weeklyOrderId, data }: TUpdateGrowingPlanMutationParams
): Promise<void> {
  try {
    await apiClient.put(`/app/orders/weekly/${weeklyOrderId}/draft`, data)
  } catch (e: any) {
    throw new Error(e)
  }
}

export const useSaveDraftWeeklyOrderMutation = () => {
  const queryClient = useQueryClient()

  return useMutation<void, Error, TUpdateGrowingPlanMutationParams>(saveDraftWeeklyOrder, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(
        [QuantityReportsQueryKeys.OrdersCalendar]
      )
    }
  })
}

async function weeklyInit(params: TWeeklyInitParams): Promise<TWeeklyInitResponse> {
  try {
    const response = await apiClient('/app/orders/weekly/init', { params })
    return response.data
  } catch (e: any) {
    throw new Error(e)
  }
}

export function useWeeklyInitQuery(params: TWeeklyInitParams) {
  const keys = [QueryKeys.WeeklyInit, params.growingPlanId, params?.buyerId, params?.firstWeekDate]
  return useQuery<TWeeklyInitResponse, Error>(
    [keys],
    () => weeklyInit(params),
    {
      enabled: !!params?.growingPlanId || !!params?.buyerId
    }
  )
}
