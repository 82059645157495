import { useTranslation } from 'react-i18next'
import { Control } from 'react-hook-form'
import { FC, useMemo } from 'react'
import classNames from 'classnames'
import { de, enGB, fr, it } from 'date-fns/locale'
import { format } from 'date-fns'
import styles from './StockCard.module.scss'
import CustomInput from '../../../../../shared/components/formControls/CustomInput/CustomInput'
import { addZeros, floatFormat } from '../../../../../shared/utils/formUtils'
import { dateMonth, dayOfWeek } from '../../../../../shared/constants/date'
import { useValidationRules } from '../../../../../shared/hooks/useValidationRules'
import { useBaseQuantityFormContext } from '../../state/BaseQuantityFormState'
import { useLanguageSwitcherContext } from '../../../../languageSwitcher'
import { TLanguage } from '../../../../account/accountBase'
import { roundNumber } from '../../../../../shared/utils/calculationUtils'

const languageMap: {[key in TLanguage]: Locale} = {
  en: enGB,
  de,
  it,
  fr
}

function useDateValues(date: Date) {
  const { language } = useLanguageSwitcherContext()

  const locale = languageMap[language]

  return useMemo(() => ({
    dayOfWeek: format(date, dayOfWeek, { locale }),
    month: format(date, dateMonth, { locale })
  }), [date, locale])
}

function useIsStockAmountVisible() {
  const { isSeller } = useBaseQuantityFormContext()
  return isSeller
}

type TProps = {
  date: Date,
  orderIndex: number
  handlePricePerCuDirty: (value: string, result: string) => void
  estimate?: number | null,
  stockAmount?: number,
  quantity?: number,
  control: Control<any>
  highlight?: boolean
  disabled?: boolean
  quantityIndex: number,
  unit: string
}

const StockCard: FC<TProps> = ({
  date,
  control,
  highlight,
  estimate,
  handlePricePerCuDirty,
  stockAmount,
  quantity,
  disabled,
  quantityIndex,
  orderIndex,
  unit
}) => {
  const { month, dayOfWeek } = useDateValues(date)
  const { t } = useTranslation()
  const { weekDays } = useBaseQuantityFormContext()
  const baseInputName = `orderItems[${quantityIndex}].orders[${orderIndex}]`
  const { requiredRule } = useValidationRules()
  const isStockAmountVisible = useIsStockAmountVisible()

  return (
    <div className={classNames(styles.wrapper, {
      [styles.highlight]: highlight,
      [styles.noBorderRight]: orderIndex === weekDays.length - 1
    })}>
      <div className={styles.header}>
        <h1 className={styles.label}>{month}</h1>
        <p className={styles.description}>{dayOfWeek}</p>
        {/* {isStockAmountVisible && ( */}
        {/*  <div className={classNames( */}
        {/*    styles.stockText, */}
        {/*    { [styles.stockTextError]: (stockAmount ?? 0) < 0 } */}
        {/*  )}> */}
        {/*    {t('quantity.stock')} */}
        {/*    &nbsp; */}
        {/*    <span> */}
        {/*      {stockAmount !== undefined ? `${roundNumber(stockAmount)}${unit}` : '-'} */}
        {/*    </span> */}
        {/*  </div> */}
        {/* )} */}
        {/* <p className={styles.textItalic}> */}
        {/*  {t('quantity.estimate')} */}
        {/*  {' '} */}
        {/*  <span> */}
        {/*    {estimate ?? '-'} */}
        {/*    {unit} */}
        {/*  </span> */}
        {/* </p> */}
      </div>
      <div className={styles.body}>
        <div className={styles.order}>
          <div className={styles.textFieldWrapper}>
            <CustomInput
              disabled={disabled}
              textFieldClassName={styles.textField}
              label={<p className={styles.textFieldLabel}>{t('quantity.orderQuantityTU')}</p>}
              name={`${baseInputName}.quantityTu`}
              rules={{
                required: requiredRule()
              }}
              handleChange={floatFormat}
              control={control}
            />
          </div>
          <p className={classNames(styles.textItalic, styles.marginTop)}>
            {t('quantity.quantity')}
            {' '}
            <span>
              {quantity !== undefined ? `${roundNumber(quantity)}${unit}` : '-'}
            </span>
          </p>
        </div>
        <div className={styles.textFieldWrapper}>
          <CustomInput
            disabled={disabled}
            textFieldClassName={styles.textField}
            label={<p className={styles.textFieldLabel}>{t('quantity.pricePerCU')}</p>}
            control={control}
            rules={{
              required: requiredRule()
            }}
            handleBlur={(value) => {
              const result = addZeros(value)
              handlePricePerCuDirty(value, result)
              return result
            }}
            handleChange={floatFormat}
            name={`${baseInputName}.pricePerCu`}
          />
        </div>
      </div>
    </div>
  )
}

export default StockCard
