import { TooltipProps, Tooltip as MuiTooltip } from '@mui/material'
import { FC, ReactNode } from 'react'
import styles from './Tooltip.module.scss'

type TProps = {
  children: ReactNode
} & TooltipProps
const Tooltip: FC<TProps> = ({ children, ...rest }) => (
  <MuiTooltip {...rest} componentsProps={{ tooltip: { className: styles.tooltip } }}>
    {children}
  </MuiTooltip>
)

export default Tooltip
