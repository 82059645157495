import { useTranslation } from 'react-i18next'
import { FC, useCallback, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { format, parse } from 'date-fns'
import classNames from 'classnames'
import PrimaryLayout from '../../../../../layouts/primaryLayout/components/primaryLayout/PrimaryLayout'
import {
  QuantityAccordion,
  TForm,
  useReportIdParam
} from '../../../BaseQuantityReports'
import styles from './DailyOrder.module.scss'
import PrimaryButton from '../../../../../shared/components/buttons/PrimaryButton/PrimaryButton'
import SecondaryButton from '../../../../../shared/components/buttons/SecondaryButton/SecondaryButton'
import { dayOfWeek, DOT_DATE_FORMAT } from '../../../../../shared/constants/date'
import { TDailyOrderData, TUpdateDailyGrowingPlanData } from '../../types'
import { useIsEditMode } from '../../../BaseQuantityReports/hooks/editModeHooks'
import {
  useConfirmDailyOrderMutation,
  useCreateDailyOrderMutation,
  useOrdersDailyQuery,
  useUpdateDailyOrderMutation
} from '../../queries'
import { useControlsDisableState } from '../../../BaseQuantityReports/hooks/useControlsDisableState'
import { useOrdersWeeklyQuery } from '../../../WeeklyReport/queries'
import { TWeeklyOrderData } from '../../../WeeklyReport/types'
import { useBuyerId, useFormInit, useSellerId } from '../../../WeeklyReport/components/WeeklyReport/WeeklyReport'
import { useDisabledProductModels } from '../../../BaseQuantityReports/hooks/useDisabledProductModels'
import { useIsSellerByWeeklyOrder } from '../../../BaseQuantityReports/hooks/useIsSellerByWeeklyOrder'
import Spinner from '../../../../../shared/components/Spinner/Spinner'
import { useDate } from '../../../BaseQuantityReports/hooks/useDate'
import { useNavigateToCalendar } from '../../../BaseQuantityReports/hooks/useNavigateToCalendar'
import DailyOrderHeader from '../DailyOrderHeader/DailyOrderHeader'
import { useDeliveryDaysSettings } from '../../../BaseQuantityReports/hooks/useDeliveryDaysSettings'
import { dateFromDotDateFormat } from '../../../../../shared/utils/date'

function useWeeklyOrderIdSearchParam() {
  const [searchParams] = useSearchParams()
  return searchParams.get('weeklyOrderId') ?? undefined
}

function prepareData(
  weeklyOrderId: string,
  date: string,
  data: TForm
): TUpdateDailyGrowingPlanData {
  return {
    weeklyOrderId,
    date,
    dailyOrderItems: data.orderItems.map((item) => {
      const order = item.orders.find(({ date: orderDate }) => date === orderDate)
      return ({
        productId: item.productId as string,
        labelId: item.labelId as string,
        cu: item.cu as string,
        amountPerCu: item.amountPerCu as number,
        tu: item.tu as string,
        amountPerTU: item.amountPerTU as number,
        lu: item.lu as string,
        amountPerLu: item.amountPerLu as number,
        order: {
          date,
          id: order?.id,
          quantityTu: order?.quantityTu ?? 0,
          pricePerCu: order?.pricePerCu ?? 0
        }
      })
    })
  }
}

function useGetGrowingPlanInfo(weeklyOrdersData: TWeeklyOrderData) {
  return useMemo(() => {
    if (!weeklyOrdersData) {
      return ''
    }
    const {
      growingPlanTitle,
      currentUserSeller,
      seller,
      buyer } = weeklyOrdersData

    return `${growingPlanTitle} (${currentUserSeller ? buyer.name : seller.name})`
  }, [weeklyOrdersData])
}

function useDayDateSearchParam() {
  const [searchParams] = useSearchParams()

  const dateString = searchParams.get('date')

  if (!dateString) {
    return undefined
  }

  return parse(
    dateString,
    DOT_DATE_FORMAT,
    new Date()
  )
}

function useDailyOrderId() {
  return useReportIdParam()
}

function useOnSubmit(onSuccess: () => void, orderData?: TDailyOrderData) {
  const { mutateAsync: createMutateAsync } = useCreateDailyOrderMutation()
  const { mutateAsync: updateMutateAsync } = useUpdateDailyOrderMutation()

  const isEditMode = useIsEditMode()
  const dailyOrderId = useDailyOrderId()
  const weeklyOrderIdSearchParam = useWeeklyOrderIdSearchParam()
  const dayDateSearchParam = useDayDateSearchParam()

  return useCallback((data: TForm) => {
    if (isEditMode && dailyOrderId && orderData) {
      updateMutateAsync({
        data: prepareData(orderData.weeklyOrderId, orderData.date, data),
        weeklyOrderId: orderData.weeklyOrderId,
        dailyOrderId
      }).then(onSuccess)

      return
    }

    if (weeklyOrderIdSearchParam && dayDateSearchParam) {
      createMutateAsync(
        prepareData(weeklyOrderIdSearchParam, format(dayDateSearchParam, DOT_DATE_FORMAT), data)
      ).then(onSuccess)
    }
  }, [isEditMode, dailyOrderId, orderData, weeklyOrderIdSearchParam, dayDateSearchParam])
}

function useOnConfirm(onSuccess: () => void) {
  const { mutateAsync: createMutateAsync } = useConfirmDailyOrderMutation()
  const reportId = useReportIdParam()

  return useCallback(() => {
    if (reportId) {
      createMutateAsync(reportId).then(onSuccess)
    }
  }, [reportId])
}

function useOnlyDay(fallback?: string) {
  const dayDateSearchParam = useDayDateSearchParam()
  return dayDateSearchParam ? format(dayDateSearchParam, DOT_DATE_FORMAT) : fallback
}

type TProps = {
  dailyOrdersData?: TDailyOrderData
  weeklyOrdersData: TWeeklyOrderData
}
const DailyOrder: FC<TProps> = ({ dailyOrdersData, weeklyOrdersData }) => {
  const date = useDate(weeklyOrdersData?.firstWeekDate)

  const {
    useFormReturn,
    initialFormData,
    fieldArrayReturn
  } = useFormInit(date, weeklyOrdersData.weeklyOrderItems)
  const { t } = useTranslation()
  const { handleSubmit } = useFormReturn
  const sellerId = useSellerId()
  const buyerId = useBuyerId()

  const navigateToCalendar = useNavigateToCalendar(weeklyOrdersData.growingPlanId, date, sellerId || '', buyerId || '')

  const onSubmit = useOnSubmit(() => navigateToCalendar(), dailyOrdersData)

  const onConfirm = useOnConfirm(() => navigateToCalendar())

  const {
    isSubmitDisabled,
    isConfirmDisabled
  } = useControlsDisableState(
    initialFormData,
    useFormReturn,
    dailyOrdersData?.editableForCurrentUser
  )

  const dateDailyOrder = useOnlyDay(dailyOrdersData?.date)
  const isEditMode = useIsEditMode()
  const SubmitButton = useMemo(() => (isEditMode ? SecondaryButton : PrimaryButton), [isEditMode])

  const isSeller = useIsSellerByWeeklyOrder(weeklyOrdersData)

  const growingPlanInfo = useGetGrowingPlanInfo(weeklyOrdersData)

  const weeklyOrderId = useMemo(() => (isEditMode
    ? weeklyOrdersData?.weeklyOrderId
    : undefined),
  [isEditMode, weeklyOrdersData])
  const deliveryDaysSettings = useDeliveryDaysSettings()
  const ordersDeliveryDaysSettings = weeklyOrdersData.deliveryDays
    .map((day) => format(dateFromDotDateFormat(day), dayOfWeek).toUpperCase())!
  return (
    <PrimaryLayout
      noPadding
      size="large"
      maxHeight
      header={<DailyOrderHeader dateDailyOrder={dateDailyOrder} growingPlanInfo={growingPlanInfo}/>}
    >
      <QuantityAccordion
        onlyDay={useOnlyDay(dailyOrdersData?.date)}
        fieldArrayReturn={fieldArrayReturn}
        disabledProductModels={useDisabledProductModels(weeklyOrdersData)}
        formReturn={useFormReturn}
        date={date}
        isProductAddable={!isSeller}
        isSeller={isSeller}
        weeklySellerId={weeklyOrdersData?.seller.id}
        weeklyOrderId={weeklyOrderId}
        growingPlanId={weeklyOrdersData.growingPlanId || ''}
        deliveryDays={isEditMode ? ordersDeliveryDaysSettings : deliveryDaysSettings}
      />
      <div className={styles.line}/>
      <div className={classNames(styles.buttonGroup, { [styles.buttonGroupEdit]: isEditMode })}>
        {isEditMode && (
          <PrimaryButton
            disabled={isConfirmDisabled}
            className={styles.button}
            onClick={onConfirm}
            text={t('quantity.daily.confirm')}/>
        )}
        <SubmitButton className={styles.button} disabled={isSubmitDisabled} onClick={handleSubmit(onSubmit)} text={t('quantity.daily.submit')}/>
        <SecondaryButton className={styles.button} onClick={() => navigateToCalendar()} text={t('common.cancel')}/>
      </div>
    </PrimaryLayout>
  )
}

export default () => {
  const { data: dailyOrdersData } = useOrdersDailyQuery(useDailyOrderId())

  const { data: weeklyOrdersData } = useOrdersWeeklyQuery(
    useDailyOrderId()
      ? dailyOrdersData?.weeklyOrderId
      : useWeeklyOrderIdSearchParam()
  )

  if (!weeklyOrdersData) {
    return (
      <div className={styles.spinnerWrapper}>
        <Spinner/>
      </div>
    )
  }

  return (
    <DailyOrder
      dailyOrdersData={dailyOrdersData}
      weeklyOrdersData={weeklyOrdersData}/>
  )
}
