import { useSearchParams } from 'react-router-dom'
import { useIsEditMode } from './editModeHooks'
import { NO_GROWING_PLAN_OPTION } from '../constants/quantityReports'

export function useGrowingPlanId(growingPlanId?: string | null) {
  const [searchParams] = useSearchParams()
  const searchParamGrowingPlanId = searchParams.get('growingPlanId') || undefined
  return useIsEditMode() ? growingPlanId
    : searchParamGrowingPlanId === NO_GROWING_PLAN_OPTION
      ? undefined
      : searchParamGrowingPlanId
}
