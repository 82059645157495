import { useTranslation } from 'react-i18next'
import { format } from 'date-fns'
import { useNavigate } from 'react-router-dom'
import { FC, useMemo } from 'react'
import en from 'date-fns/locale/en-US'
import fr from 'date-fns/locale/fr'
import de from 'date-fns/locale/de'
import it from 'date-fns/locale/it'
import styles from './DetailsEditModal.module.scss'
import PrimaryButton from '../../../../../shared/components/buttons/PrimaryButton/PrimaryButton'
import SecondaryButton from '../../../../../shared/components/buttons/SecondaryButton/SecondaryButton'
import QuantityReportTable from '../QuantityReportTable/QuantityReportTable'
import { useDailyOrder } from '../../queries'
import { useDayDisabledToEditCheck } from '../../hooks/useIsDayDisabledToEdit'
import { dateFromDotDateFormat } from '../../../../../shared/utils/date'
import { fullDayDateFormat } from '../../../../../shared/constants/date'
import Modal from '../../../../../shared/components/Modal/Modal'
import { TOrdersCalendarDataItem } from '../../types'
import { TLanguage } from '../../../../account/accountBase'
import { useLanguageSwitcherContext } from '../../../../languageSwitcher'

const locales: {[key in TLanguage]: Locale} = {
  en, fr, de, it
}

type TProps = {
  details?: TOrdersCalendarDataItem,
  onClose: () => void
}
const DetailsEditModal: FC<TProps> = ({ details, onClose }) => {
  const { t } = useTranslation()
  const { language } = useLanguageSwitcherContext()
  const navigate = useNavigate()
  const { data } = useDailyOrder(details?.dailyOrderId)

  const checkDayDisabledToEdit = useDayDisabledToEditCheck(
    details?.startDate ? dateFromDotDateFormat(details?.startDate) : undefined
  )

  const date = useMemo(() => {
    const startDate = details?.startDate
    if (!startDate) {
      return undefined
    }
    return format(
      dateFromDotDateFormat(details.startDate)!, fullDayDateFormat,
      { locale: locales[language] }
    )
  }, [details?.startDate, language])

  if (!data) {
    return <></>
  }

  const handleEdit = () => {
    const orderId = details?.dailyOrderId!
    if (checkDayDisabledToEdit(true)) {
      onClose()
      return
    }
    navigate(`daily/${orderId}`)
  }

  return (
    <Modal
      large
      modalClass={styles.modal}
      isOpen={!!details?.dailyOrderId}
      onClose={onClose}>
      <h1 className={styles.title}>
        {t('quantity.quantityReports')}
        {` - ${data.growingPlanTitle}`}
      </h1>
      <p className={styles.label}>{date}</p>

      <QuantityReportTable dailyOrderItems={data.dailyOrderItems}/>

      <div className={styles.line}/>
      <div className={styles.buttonGroup}>
        {data.editableForCurrentUser && (
          <div className={styles.editButtonWrap}>
            <PrimaryButton onClick={handleEdit} text={t('common.edit')}/>
          </div>
        )}

        <div className={styles.cancelButtonWrap}>
          <SecondaryButton onClick={onClose} text={t('common.close')}/>
        </div>
      </div>
    </Modal>
  )
}

export default DetailsEditModal
