import { useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FC, useCallback, useEffect, useMemo } from 'react'
import { format } from 'date-fns'
import classNames from 'classnames'
import { useSearchParams } from 'react-router-dom'
import PrimaryLayout from '../../../../../layouts/primaryLayout/components/primaryLayout/PrimaryLayout'
import {
  defaultOrderFormData,
  QuantityAccordion,
  TForm,
  useReportIdParam
} from '../../../BaseQuantityReports'
import styles from './WeeklyReport.module.scss'
import PrimaryButton from '../../../../../shared/components/buttons/PrimaryButton/PrimaryButton'
import SecondaryButton from '../../../../../shared/components/buttons/SecondaryButton/SecondaryButton'
import {
  useConfirmWeeklyOrderMutation,
  useCopyPreviousWeeklyReportQuery,
  useCreateWeeklyOrderMutation,
  useOrdersWeeklyQuery,
  useSaveDraftWeeklyOrderMutation,
  useUpdateWeeklyOrderMutation, useWeeklyInitQuery
} from '../../queries'
import { TUpdateWeeklyGrowingPlanData, TWeeklyOrderData, TWeeklyOrderDataItem } from '../../types'
import { currentWeekDays } from '../../../BaseQuantityReports/utils/generateFormData'
import { dayOfWeek, DOT_DATE_FORMAT } from '../../../../../shared/constants/date'
import { useIsDraft, useIsEditMode } from '../../../BaseQuantityReports/hooks/editModeHooks'
import { useNavigateToCalendar } from '../../../BaseQuantityReports/hooks/useNavigateToCalendar'
import { useControlsDisableState } from '../../../BaseQuantityReports/hooks/useControlsDisableState'
import { useGrowingPlanId } from '../../../BaseQuantityReports/hooks/useGrowingPlanId'
import { useDisabledProductModels } from '../../../BaseQuantityReports/hooks/useDisabledProductModels'
import { useIsSellerByWeeklyOrder } from '../../../BaseQuantityReports/hooks/useIsSellerByWeeklyOrder'
import Spinner from '../../../../../shared/components/Spinner/Spinner'
import { useDate } from '../../../BaseQuantityReports/hooks/useDate'
import { useIsCreatingMode } from '../../../BaseQuantityReports/hooks/useIsCreatingMode'
import { addZeros } from '../../../../../shared/utils/formUtils'
import WeeklyDailyHeader from '../WeeklyHeader/WeeklyDailyHeader'
import { useDeliveryDaysSettings } from '../../../BaseQuantityReports/hooks/useDeliveryDaysSettings'
import { dateFromDotDateFormat, dotDateFormat } from '../../../../../shared/utils/date'

export function useBuyerId() {
  const [searchParams] = useSearchParams()
  return searchParams.get('buyerId') || undefined
}

export function useSellerId() {
  const [searchParams] = useSearchParams()
  return searchParams.get('sellerId') || undefined
}

function prepareInitialFormItems(date?: Date, orderItems?: TWeeklyOrderDataItem[]) {
  if (!date) {
    return []
  }

  if (!orderItems) {
    return [defaultOrderFormData(date)]
  }

  return orderItems.map((item) => ({
    orders: currentWeekDays(date).map((date) => {
      const foundOrder = item.orders.find(
        ({ date: orderDate }) => format(date, DOT_DATE_FORMAT) === orderDate
      )

      return {
        date: foundOrder?.date ?? format(date, DOT_DATE_FORMAT),
        quantityTu: foundOrder?.quantityTu ?? 0,
        pricePerCu: addZeros(foundOrder?.pricePerCu ?? 0),
        id: foundOrder?.id
      }
    }),
    unit: item.unit,
    labelId: item.label?.id,
    amountPerCu: item.amountPerCu,
    amountPerLu: item.amountPerLu,
    amountPerTU: item.amountPerTU,
    productId: item.product?.id,
    tu: item.tu,
    lu: item.lu,
    cu: item.cu
  }))
}

function useGetGrowingPlanInfo(
  growingPlanId?: string | null,
  date?: Date,
  ordersData?: TWeeklyOrderData
) {
  const buyerId = useBuyerId()

  const { data: growingPlanData } = useWeeklyInitQuery({
    growingPlanId,
    buyerId,
    firstWeekDate: dotDateFormat(date!)
  })
  return useMemo(() => {
    if (!growingPlanData && !ordersData) {
      return ''
    }
    const data = (ordersData || growingPlanData)!

    return `${data.growingPlanTitle} (${data.currentUserSeller ? data.buyer.name : data.seller.name})`
  }, [growingPlanData, ordersData])
}

function useGetSearchParams(searchParamValue: 'copy' | 'readOnly') {
  const [searchParams] = useSearchParams()
  return searchParams.get(searchParamValue)
}

export function useFormInit(date?: Date, orderItems?: TWeeklyOrderDataItem[]) {
  const initialFormData = useMemo(() => ({
    orderItems: prepareInitialFormItems(date, orderItems)
  }), [date, orderItems])
  const useFormReturn = useForm<TForm>({})
  const fieldArrayReturn = useFieldArray({ control: useFormReturn.control, name: 'orderItems' })

  useEffect(() => {
    prepareInitialFormItems(date, orderItems).forEach((item) => {
      fieldArrayReturn.append(item)
    })

    // eslint-disable-next-line consistent-return
    return () => {
      useFormReturn.reset()
    }
  }, [])

  return {
    useFormReturn,
    initialFormData,
    fieldArrayReturn
  }
}

function prepareData(
  data: TForm,
  growingPlanId?: string | null,
  buyerId?: string,
  date?: Date,
  publish?: boolean
): TUpdateWeeklyGrowingPlanData {
  return {
    growingPlanId,
    buyerId,
    publish,
    firstWeekDate: format(currentWeekDays(date)[0], DOT_DATE_FORMAT),
    weeklyOrderItems: data.orderItems.map((item) => ({
      productId: item.productId as string,
      labelId: item.labelId as string,
      cu: item.cu as string,
      amountPerCu: item.amountPerCu as number,
      tu: item.tu as string,
      amountPerTU: item.amountPerTU as number,
      lu: item.lu as string,
      amountPerLu: item.amountPerLu as number,
      orders: item.orders.map(({ date, pricePerCu, quantityTu, id }) => ({
        date,
        pricePerCu,
        quantityTu,
        id
      }))
    }))
  }
}

function useOnSubmit(onSuccess: () => void, growingPlanId?: string | null, date?: Date) {
  const { mutateAsync: createMutateAsync } = useCreateWeeklyOrderMutation()
  const { mutateAsync: updateMutateAsync } = useUpdateWeeklyOrderMutation()
  const { mutateAsync: saveDraftMutateAsync } = useSaveDraftWeeklyOrderMutation()
  const isEditMode = useIsEditMode()
  const reportId = useReportIdParam()
  const buyerId = useBuyerId()
  return useCallback(({ data, publish, saveDraft
  }: {data: TForm, publish: boolean, saveDraft?: boolean }) => {
    if (!growingPlanId && !buyerId) {
      return
    }

    if (saveDraft && reportId) {
      saveDraftMutateAsync({
        data: prepareData(data, growingPlanId, buyerId, date),
        weeklyOrderId: reportId
      }).then(onSuccess)
      return
    }

    if (isEditMode && reportId) {
      updateMutateAsync({
        data: prepareData(data, growingPlanId, buyerId, date),
        weeklyOrderId: reportId
      }).then(onSuccess)

      return
    }

    createMutateAsync(prepareData(data, growingPlanId, buyerId, date, publish)).then(onSuccess)
  }, [growingPlanId, buyerId])
}

function useOnConfirm(onSuccess: () => void) {
  const { mutateAsync: createMutateAsync } = useConfirmWeeklyOrderMutation()
  const reportId = useReportIdParam()

  return useCallback(() => {
    if (reportId) {
      createMutateAsync(reportId).then(onSuccess)
    }
  }, [reportId])
}

function useIsSeller(ordersData?: TWeeklyOrderData) {
  const isCreatingMode = useIsCreatingMode()
  const isSellerByWeeklyOrder = useIsSellerByWeeklyOrder(ordersData)
  return isCreatingMode || isSellerByWeeklyOrder
}
type TProps = {
  ordersData?: TWeeklyOrderData
  growingPlanId?: string | null
}
const WeeklyReport: FC<TProps> = ({ ordersData, growingPlanId }) => {
  const date = useDate(ordersData?.firstWeekDate)
  const { useFormReturn,
    initialFormData,
    fieldArrayReturn } = useFormInit(date, ordersData?.weeklyOrderItems)
  const { handleSubmit } = useFormReturn
  const { t } = useTranslation()
  const sellerId = useSellerId()
  const buyerId = useBuyerId()
  const growingPlanInfo = useGetGrowingPlanInfo(growingPlanId, date, ordersData)
  const navigateToCalendar = useNavigateToCalendar(growingPlanId, date, sellerId || '', buyerId || '')
  const isReadOnly = !!useGetSearchParams('readOnly')
  const onSubmit = useOnSubmit(() => navigateToCalendar(), growingPlanId, date)
  const isSeller = useIsSeller(ordersData)
  const onConfirm = useOnConfirm(() => navigateToCalendar())
  const {
    isSubmitDisabled,
    isConfirmDisabled,
    isSaveForLaterDisabled
  } = useControlsDisableState(initialFormData, useFormReturn, ordersData?.editableForCurrentUser)
  const isEditMode = useIsEditMode()
  const isDraft = useIsDraft()

  const SubmitButton = useMemo(() =>
    ((isEditMode && !isDraft) ? SecondaryButton : PrimaryButton),
  [isEditMode])

  const weeklyOrderId = useMemo(() => (isEditMode || isReadOnly
    ? ordersData?.weeklyOrderId
    : undefined),
  [isEditMode, isReadOnly, ordersData])

  const deliveryDaysSettings = useDeliveryDaysSettings()
  const ordersDeliveryDaysSettings = ordersData?.deliveryDays
    .map((day) => format(dateFromDotDateFormat(day), dayOfWeek).toUpperCase())!

  return (
    <PrimaryLayout
      noPadding
      size="large"
      maxHeight
      header={(
        <WeeklyDailyHeader
          title={t('quantity.weeklyReport')}
          description={growingPlanInfo}
        />
      )}
    >
      <QuantityAccordion
        weeklyOrderId={weeklyOrderId}
        readOnly={isReadOnly}
        formReturn={useFormReturn}
        fieldArrayReturn={fieldArrayReturn}
        isSeller={isSeller}
        date={date}
        disabledProductModels={useDisabledProductModels(ordersData)}
        deliveryDays={weeklyOrderId ? ordersDeliveryDaysSettings : deliveryDaysSettings}
        growingPlanId={growingPlanId}
        buyerId={useBuyerId()}
        weeklySellerId={ordersData?.seller.id}
      />
      <div className={styles.line} />
      <div className={classNames(styles.buttonGroup, { [styles.buttonGroupEdit]: isEditMode })}>
        {(isEditMode && !isDraft && !isReadOnly) && (
          <PrimaryButton
            disabled={isConfirmDisabled}
            className={styles.button}
            onClick={onConfirm}
            text={t('quantity.weekly.confirm')}/>
        )}
        {!isReadOnly
          && (
            <>
              <SubmitButton
                disabled={isSubmitDisabled}
                className={styles.button}
                onClick={handleSubmit((data) => onSubmit({ data, publish: true }))}
                text={t('quantity.weekly.submit')}/>

              <SecondaryButton
                disabled={isSaveForLaterDisabled}
                className={styles.button}
                onClick={handleSubmit((data) => onSubmit({
                  data,
                  publish: false,
                  saveDraft: true
                }))}
                text={t('quantity.weekly.saveForLater')}/>
            </>
          )}

        <SecondaryButton
          className={styles.button}
          onClick={() => navigateToCalendar()}
          text={t('common.cancel')}/>
      </div>
    </PrimaryLayout>
  )
}

export default () => {
  const { data: weeklyOrdersData } = useOrdersWeeklyQuery(useReportIdParam())
  const growingPlanId = useGrowingPlanId(weeklyOrdersData?.growingPlanId)
  const { data: copyWeeklyOrdersData } = useCopyPreviousWeeklyReportQuery()
  const isCopy = useGetSearchParams('copy')

  if (
    (useIsEditMode() && !weeklyOrdersData)
    || (isCopy && !copyWeeklyOrdersData)) {
    return (
      <div className={styles.spinnerWrapper}>
        <Spinner/>
      </div>
    )
  }

  return (
    <WeeklyReport
      ordersData={weeklyOrdersData || copyWeeklyOrdersData}
      growingPlanId={growingPlanId}
    />
  )
}
