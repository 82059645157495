import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import EditIcon from '../../../../../../shared/components/icons/EditIcon'
import PrimaryButton from '../../../../../../shared/components/buttons/PrimaryButton/PrimaryButton'
import styles from './EditQuantity.module.scss'
import { TOrdersCalendarDataItem } from '../../../types'
import { useDayDisabledToEditCheck } from '../../../hooks/useIsDayDisabledToEdit'
import { dateFromDotDateFormat } from '../../../../../../shared/utils/date'
import { useQuantityReportsContext } from '../../../state/QuantityReportsState'

type TProps = {
  item: TOrdersCalendarDataItem
}
const EditQuantity: FC<TProps> = ({ item: { dailyOrderId, startDate } }) => {
  const navigate = useNavigate()
  const checkDayDisabledToEdit = useDayDisabledToEditCheck(dateFromDotDateFormat(startDate))
  const { formMethods: { getValues } } = useQuantityReportsContext()
  return (
    <PrimaryButton
      className={styles.buttonIcon}
      onClick={() => {
        if (!checkDayDisabledToEdit(true)) {
          const { seller, buyer } = getValues()
          navigate(`daily/${dailyOrderId}?buyerId=${buyer}&sellerId=${seller}`)
        }
      }}
    >
      <EditIcon width={22} height={22}/>
    </PrimaryButton>
  )
}
export default EditQuantity
