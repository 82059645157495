import React, { useMemo, useState } from 'react'
import classNames from 'classnames'
import { MenuItem, Menu } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import format from 'date-fns/format'
import styles from './NotificationMenu.module.scss'
import BellIcon from '../../../shared/components/icons/BellIcon'
import { useTablet } from '../../../shared/hooks/useDevice'
import { useNotificationListQuery, useNotificationReadMutation } from '../querries'
import BellActiveIcon from '../../../shared/components/icons/BellActiveIcon'
import { TOrderType } from '../types'
import { dateFromDotDateFormat } from '../../../shared/utils/date'

const NotificationMenu = () => {
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const isTablet = useTablet()
  const { data } = useNotificationListQuery()
  const { mutateAsync } = useNotificationReadMutation()
  const navigate = useNavigate()

  const hasUnreadMessages = useMemo(() => (data
    ? data.some(({ isRead }) => !isRead)
    : false), [data])

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const isOpen = Boolean(anchorEl)

  function handleNavigate(type: TOrderType, orderId: string) {
    const order = type === 'WEEKLY_ORDER' ? 'weekly' : 'daily'
    navigate(`/quantity-reports/${order}/${orderId}`)
    handleClose()
  }

  return (
    <div className={styles.wrapper}>
      <button
        type="button"
        onClick={handleClick}
        className={classNames(styles.content, { [styles.bordered]: isOpen })}
      >
        {
          hasUnreadMessages
            ? <BellActiveIcon width={20} height={22}/>
            : <BellIcon width={20} height={22}/>
        }
      </button>
      <Menu
        disablePortal
        disableAutoFocusItem
        elevation={0}
        open={isOpen}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        PopoverClasses={{ paper: isTablet && styles.popover }}
        MenuListProps={{
          classes: {
            root: classNames(styles.menuListRoot, { [styles.large]: isTablet })
          }
        }}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        {
          data?.length
            ? data.map(({
              type,
              id,
              from,
              isRead,
              orderId,
              orderDate,
              startDate,
              endDate
            }) => {
              const isWeek = type === 'WEEKLY_ORDER'
              return (
                <MenuItem
                  className={styles.menuItem}
                  key={id}
                  onClick={() => {
                    if (isRead) {
                      handleNavigate(type, orderId)
                      return
                    }
                    mutateAsync(id).then(() => handleNavigate(type, orderId))
                  }}>
                  <div className={styles.menuItemContent}>
                    <div className={classNames(styles.contentGroup, { [styles.active]: !isRead })}>
                      {!isRead && <div className={styles.bell}/>}
                      <div>
                        <p className={styles.title}>
                          {t(isWeek
                            ? 'notification.confirmWeeklyReport'
                            : 'notification.confirmDailyOrder')}
                        </p>
                        <p className={styles.author}>{from.name}</p>
                        <p className={styles.date}>
                          {`${t(isWeek ? 'dashboard.week' : 'dashboard.date')}:`}
                          {' '}
                          {isWeek
                            ? `${format(dateFromDotDateFormat(startDate), 'dd.MM')} - ${endDate}`
                            : orderDate}
                        </p>
                      </div>
                    </div>
                    <div className={styles.line}/>
                  </div>
                </MenuItem>
              )
            })
            : <div className={styles.noNotification}>{t('notification.noData')}</div>
        }
      </Menu>
    </div>
  )
}

export default NotificationMenu
