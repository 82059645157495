import { FC, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import PrimaryButton from '../../../../../../shared/components/buttons/PrimaryButton/PrimaryButton'
import styles from './AddQuantity.module.scss'
import AddLineIcon from '../../../../../../shared/components/icons/AddLineIcon'
import { dotDateFormat } from '../../../../../../shared/utils/date'
import { TOrdersCalendarDataItem } from '../../../types'
import { useDayDisabledToEditCheck } from '../../../hooks/useIsDayDisabledToEdit'
import { useQuantityReportsContext } from '../../../state/QuantityReportsState'

type TProps = {
  weeklyItem: TOrdersCalendarDataItem,
  date: Date
}
const AddQuantity: FC<TProps> = ({ date, weeklyItem }) => {
  const navigate = useNavigate()
  const { formMethods: { getValues } } = useQuantityReportsContext()
  const checkDayDisabledToEdit = useDayDisabledToEditCheck(date)
  const isDayDisabledToEdit = useMemo(() => checkDayDisabledToEdit(false), [])

  if (isDayDisabledToEdit) {
    return null
  }

  return (
    <PrimaryButton
      onClick={() => {
        if (!checkDayDisabledToEdit(true)) {
          const { buyer, seller } = getValues()
          navigate({
            pathname: 'daily/create',
            search: `?weeklyOrderId=${weeklyItem.weeklyOrderId}&date=${dotDateFormat(date)}&buyerId=${buyer}&sellerId=${seller}`
          })
        }
      }}
      className={styles.buttonIcon}
    >
      <AddLineIcon/>
    </PrimaryButton>
  )
}

export default AddQuantity
