import { FC } from 'react'
import classNames from 'classnames'
import { format } from 'date-fns'
import styles from './AddCopyWeeklyReport.module.scss'
import { useQuantityReportsContext } from '../../../state/QuantityReportsState'
import { useCheckPreviousOrderExistQuery } from '../../../queries'
import { DOT_DATE_FORMAT } from '../../../../../../shared/constants/date'
import AddWeeklyReport from '../AddWeeklyReport/AddWeeklyReport'
import CopyWeeklyReport from '../CopyWeeklyReport/CopyWeeklyReport'

type TProps = {
  weekStartDate: Date
}

const AddCopyWeeklyReport: FC<TProps> = ({ weekStartDate }) => {
  const { selectedGrowingPlan, formMethods } = useQuantityReportsContext()
  const { data: isPreviousOrderExist } = useCheckPreviousOrderExistQuery({
    firstWeekDate: format(weekStartDate, DOT_DATE_FORMAT),
    growingPlanId: selectedGrowingPlan?.id,
    sellerId: formMethods.watch('seller'),
    buyerId: formMethods.watch('buyer'),
    monthIndex: formMethods.watch('month')
  })

  return (

    <div className={
      classNames(styles.buttonReportWrapper,
        { [styles.isDivided]: isPreviousOrderExist })
    }>
      <AddWeeklyReport weekStartDate={weekStartDate}/>
      {isPreviousOrderExist && (<CopyWeeklyReport weekStartDate={weekStartDate}/>)}
    </div>
  )
}

export default AddCopyWeeklyReport
