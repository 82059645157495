import { FC, useMemo } from 'react'
import { DateHeaderProps } from 'react-big-calendar'
import { isEqual, format } from 'date-fns'
import { useTranslation } from 'react-i18next'
import CheckIcon from '../../../../../shared/components/icons/CheckIcon'
import styles from './DateHeader.module.scss'
import WarningIcon from '../../../../../shared/components/icons/WarningIcon'
import { useQuantityReportsContext } from '../../state/QuantityReportsState'
import { dailyItems } from '../../utils/calendarEventsUtils'
import { dateFromDotDateFormat } from '../../../../../shared/utils/date'
import Tooltip from '../../../../../shared/components/Tooltip/Tooltip'

function useDailyItem(date: Date) {
  const { ordersCalendarQuery: { data: items } } = useQuantityReportsContext()

  return useMemo(() => {
    if (!items) {
      return undefined
    }

    return dailyItems(items).find(
      ({ startDate, status }) => isEqual(dateFromDotDateFormat(startDate), date)
    )
  }, [items, date])
}

type TProps = DateHeaderProps
const DateHeader: FC<TProps> = ({ date }) => {
  const dailyItem = useDailyItem(date)
  const { t } = useTranslation()

  const showStatus = !!dailyItem

  const isConfirmedStatus = dailyItem?.status === 'CONFIRMED'

  return (
    <div className={styles.dateHeaderWrapper}>
      <p className={styles.dateLabel}>{ format(date, 'd')}</p>
      {showStatus && (
        <Tooltip
          arrow
          disableFocusListener
          disableTouchListener
          placement="top"
          title={t(`quantity.tooltip.${isConfirmedStatus ? 'check' : 'warning'}`)}
        >
          {isConfirmedStatus
            ? (
              <div className={styles.iconCheck}>
                <CheckIcon/>
              </div>
            )
            : (
              <div className={styles.iconWarning}>
                <WarningIcon/>
              </div>
            )}
        </Tooltip>
      )}
    </div>
  )
}

export default DateHeader
