import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'
import en from 'date-fns/locale/en-US'
import fr from 'date-fns/locale/fr'
import de from 'date-fns/locale/de'
import it from 'date-fns/locale/it'
import { FC } from 'react'
import styles from './DailyOrderHeader.module.scss'
import { dateFromDotDateFormat } from '../../../../../shared/utils/date'
import { fullDayDateFormat } from '../../../../../shared/constants/date'
import { TLanguage } from '../../../../account/accountBase'
import { useLanguageSwitcherContext } from '../../../../languageSwitcher'

const locales: {[key in TLanguage]: Locale} = {
  en, fr, de, it
}

type TProps = {
  dateDailyOrder?: string
  growingPlanInfo: string
}
const DailyOrderHeader: FC<TProps> = ({ dateDailyOrder, growingPlanInfo }) => {
  const { t } = useTranslation()
  const { language } = useLanguageSwitcherContext()
  return (
    <div className={styles.header}>
      <div>{t('quantity.dailyOrder')}</div>
      <div className={styles.description}>{growingPlanInfo}</div>
      <div className={styles.dateDailyOrder}>
        {
          dateDailyOrder && format(
          dateFromDotDateFormat(dateDailyOrder)!,
          fullDayDateFormat,
          { locale: locales[language] }
          )
        }
      </div>
    </div>
  )
}

export default DailyOrderHeader
