import { useNavigate } from 'react-router-dom'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import PrimaryButton from '../../../../../../shared/components/buttons/PrimaryButton/PrimaryButton'
import styles from './ReviewWeeklyReport.module.scss'
import { TOrdersCalendarDataItem } from '../../../types'
import { useQuantityReportsContext } from '../../../state/QuantityReportsState'

type TProps = {
  item: TOrdersCalendarDataItem
}
const ReviewWeeklyReport: FC<TProps> = ({ item: { weeklyOrderId } }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { formMethods: { getValues } } = useQuantityReportsContext()
  const handleNavigate = () => {
    const { buyer, seller } = getValues()
    navigate(`weekly/${weeklyOrderId}?buyerId=${buyer}&sellerId=${seller}`)
  }
  return (
    <div className={styles.buttonReportWrapper}>
      <PrimaryButton
        text={t('quantity.calendar.reviewWeeklyReport')}
        onClick={handleNavigate}
        className={styles.buttonText}
      />
    </div>
  )
}

export default ReviewWeeklyReport
