import { Column } from 'react-table'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { TDailyOrderItem } from '../../types'
import styles from './QuantityReportTable.module.scss'
import { Table as CustomTable } from '../../../../../shared/components/table'
import { addZeros } from '../../../../../shared/utils/formUtils'

type TProps = {
  dailyOrderItems: TDailyOrderItem[]
}
const QuantityReportTable: FC<TProps> = ({ dailyOrderItems }) => {
  const { t } = useTranslation()
  const columns = useMemo<Column[]>(() => [
    {
      Header: () => <div className={styles.tableHeader}>{t('quantity.table.product')}</div>,
      accessor: 'product.name',
      Cell: ({ value }) => <span>{value}</span>
    },
    {
      Header: () => <div className={styles.tableHeader}>{t('quantity.table.label')}</div>,
      accessor: 'label',
      Cell: ({ value }) => <span>{value.name}</span>
    },
    {
      Header: () => <div className={styles.tableHeader}>{t('quantity.table.amountInTU')}</div>,
      accessor: 'order.quantityTu',
      Cell: ({ value }) => <span>{value}</span>
    },
    {
      Header: () => <div className={styles.tableHeader}>{t('quantity.table.cu')}</div>,
      accessor: 'cu',
      Cell: ({ value }) => <span>{t(`customerUnitOptions.${value}`)}</span>
    },
    {
      Header: () => <div className={styles.tableHeader}>{t('quantity.table.tu')}</div>,
      accessor: 'tu',
      Cell: ({ value }) => <span>{t(`tradeInOptions.${value}`)}</span>
    },
    {
      Header: () => <div className={styles.tableHeader}>{t('quantity.table.lu')}</div>,
      accessor: 'lu',
      Cell: ({ value }) => <span>{t(`logisticOptions.${value}`)}</span>
    },
    {
      Header: () => <div className={styles.tableHeader}>{t('quantity.table.pricePerCu')}</div>,
      accessor: 'order.pricePerCu',
      Cell: ({ value }) => <span>{addZeros(value)}</span>
    }

  ], [dailyOrderItems])
  return (
    <div className={styles.wrapper}>
      <CustomTable contentClass={styles.tableContent} columns={columns} data={dailyOrderItems}/>
    </div>

  )
}

export default QuantityReportTable
