import { useQuery } from '@tanstack/react-query'
import { apiClient } from '../../Interceptor'
import { TCalendarParams, TDailyOrder, TOrdersCalendarData } from './types'
import { TNameId } from '../../growingPlan/BaseGrowingPlanWorkShop'
import { NO_GROWING_PLAN_OPTION } from '../BaseQuantityReports'

export enum QuantityReportsQueryKeys{
  OrdersCalendar = 'OrdersCalendar',
  DailyOrder = 'DailyOrder',
  CheckPreviousOrderExist = 'CheckPreviousOrderExist',
  SellerList = 'SellerList',
  BuyerList = 'BuyerList'
}

async function getOrdersCalendar(
  { dateFrom, dateTo, buyerId, sellerId, growingPlanId }: TCalendarParams
): Promise<TOrdersCalendarData> {
  try {
    const response = await apiClient.get('/app/orders/calendar', {
      params: {
        dateFrom,
        dateTo,
        growingPlanId: growingPlanId === NO_GROWING_PLAN_OPTION ? null : growingPlanId,
        buyerId: buyerId || null,
        sellerId: sellerId || null
      }
    })
    return response.data
  } catch (e: any) {
    throw new Error(e)
  }
}

export const useOrdersCalendarQuery = (
  { dateTo, growingPlanId, dateFrom, buyerId, sellerId }: TCalendarParams
) => {
  const key = [QuantityReportsQueryKeys.OrdersCalendar,
    growingPlanId,
    dateFrom,
    dateTo,
    buyerId,
    sellerId
  ]
  const isValidDates = !!dateFrom && !!dateTo

  return useQuery<TOrdersCalendarData, Error>(
    [key],
    () => getOrdersCalendar({
      dateFrom,
      dateTo,
      growingPlanId,
      sellerId,
      buyerId
    }),
    {
      enabled: growingPlanId === NO_GROWING_PLAN_OPTION
        ? (!!buyerId || !!sellerId) && isValidDates
        : !!growingPlanId && isValidDates
    }
  )
}

async function getDailyOrder(orderId: string): Promise<TDailyOrder> {
  try {
    const response = await apiClient(`/app/orders/daily/${orderId}`)
    return response.data
  } catch (e: any) {
    throw new Error(e)
  }
}

export const useDailyOrder = (orderId?: string) => {
  const keys = [QuantityReportsQueryKeys.DailyOrder, orderId]
  return useQuery<TDailyOrder, Error>(
    keys,
    () => getDailyOrder(orderId!),
    {
      enabled: !!orderId
    }
  )
}

async function checkPreviousOrderExist(
  params: {
    firstWeekDate: string,
    growingPlanId?: string,
    sellerId: string | null,
    buyerId: string | null
  }
): Promise<boolean> {
  try {
    const response = await apiClient<{exists: boolean}>(
      '/app/orders/weekly/check-previous-order-exist',
      { params }
    )
    return response.data.exists
  } catch (e: any) {
    throw new Error(e)
  }
}

type TProps = {
  firstWeekDate: string
  growingPlanId?: string
  sellerId: string | null,
  buyerId: string | null
  monthIndex: string
}
export const useCheckPreviousOrderExistQuery = ({
  monthIndex, ...rest }: TProps) => {
  const keys = [
    QuantityReportsQueryKeys.CheckPreviousOrderExist,
    monthIndex,
    rest.buyerId, rest.sellerId, rest.growingPlanId
  ]

  return useQuery(
    keys,
    () => checkPreviousOrderExist(rest),
    {
      enabled: !!rest.firstWeekDate && (!!rest.growingPlanId || !!rest.buyerId || !!rest.sellerId)
    }
  )
}

async function sellerList(): Promise<TNameId[]> {
  try {
    const response = await apiClient('app/orders/weekly/sellers/list')
    return response.data
  } catch (e: any) {
    throw new Error(e)
  }
}

export const useSellerListQuery = () => {
  const keys = [QuantityReportsQueryKeys.SellerList]
  return useQuery<TNameId[], Error>(keys, sellerList)
}

async function buyerList(): Promise<TNameId[]> {
  try {
    const response = await apiClient('app/companies/list')
    return response.data
  } catch (e: any) {
    throw new Error(e)
  }
}

export const useBuyerListQuery = () => {
  const keys = [QuantityReportsQueryKeys.BuyerList]
  return useQuery<TNameId[], Error>(keys, buyerList)
}
