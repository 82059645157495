import constate from 'constate'
import { UseFieldArrayReturn, UseFormReturn } from 'react-hook-form'
import { useCallback, useMemo } from 'react'
import { currentWeekDays, generateFormData } from '../utils/generateFormData'
import { TForm, TProductModel } from '../types'

export const defaultOrderFormData = (date?: Date) => generateFormData(date)

function useAllDaysInWeek(date?: Date) {
  return useMemo(() => currentWeekDays(date), [date])
}

export type TBaseQuantityFormContextProps = {
  formReturn: UseFormReturn<any>,
  fieldArrayReturn: UseFieldArrayReturn<TForm, 'orderItems', 'id'>
  onlyDay?: string,
  growingPlanId?: string | null
  date?: Date
  disabledProductModels?: TProductModel[]
  isSeller?: boolean
  isProductAddable?: boolean
  readOnly?: boolean
  weeklyOrderId?: string
  deliveryDays: string[]
  buyerId?: string
  weeklySellerId?: string
}
const useBaseQuantityFormState = ({
  formReturn,
  growingPlanId,
  isSeller,
  date,
  onlyDay,
  disabledProductModels,
  fieldArrayReturn,
  isProductAddable = true,
  readOnly,
  weeklyOrderId,
  deliveryDays,
  buyerId,
  weeklySellerId
}: TBaseQuantityFormContextProps) => {
  const weekDays = useAllDaysInWeek(date)
  return {
    formReturn,
    fieldArrayReturn,
    append: useCallback(() => fieldArrayReturn.append(defaultOrderFormData(date)), []),
    remove: fieldArrayReturn.remove,
    growingPlanId,
    disabledProductModels,
    isSeller,
    isProductAddable,
    onlyDay,
    date,
    weekDays,
    readOnly,
    weeklyOrderId,
    deliveryDays,
    buyerId,
    weeklySellerId
  }
}

export const [
  BaseQuantityFormProvider,
  useBaseQuantityFormContext
] = constate(useBaseQuantityFormState)
