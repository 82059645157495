import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useAccountDetailsQuery } from '../../../../../account/accountBase'
import { useQuantityReportsContext } from '../../../state/QuantityReportsState'
import { dotDateFormat } from '../../../../../../shared/utils/date'
import SecondaryButton from '../../../../../../shared/components/buttons/SecondaryButton/SecondaryButton'
import styles from '../AddCopyWeeklyReport/AddCopyWeeklyReport.module.scss'
import { Payment } from '../../../../../payment'
import { NO_GROWING_PLAN_OPTION } from '../../../../BaseQuantityReports'

type TProps = {
  weekStartDate: Date
}
const CopyWeeklyReport: FC<TProps> = ({ weekStartDate }) => {
  const [isPaymentModal, setIsPaymentModal] = useState(false)
  const { t } = useTranslation()
  const { data } = useAccountDetailsQuery()
  const navigate = useNavigate()
  const { selectedGrowingPlan, formMethods: { getValues } } = useQuantityReportsContext()

  const handleNavigate = () => {
    const { buyer, seller } = getValues()
    navigate({
      pathname: 'weekly/create',
      search: `?growingPlanId=${selectedGrowingPlan?.id || NO_GROWING_PLAN_OPTION}&date=${dotDateFormat(weekStartDate)}&buyerId=${buyer}&sellerId=${seller}&copy=true`
    })
  }

  const handleClick = () => {
    // if (!data) {
    //   return
    // }
    // if (!data.company.invoicePaymentTerm || !data.company.iban) {
    //   setIsPaymentModal(true)
    //   return
    // }

    handleNavigate()
  }
  return (
    <>
      <Payment
        label={t('quantity.modals.payment.label')}
        isOpen={isPaymentModal}
        onClose={() => setIsPaymentModal(false)}
        callBack={handleNavigate}/>
      <SecondaryButton
        text={t('quantity.calendar.copyWeeklyReport')}
        onClick={handleClick}
        className={styles.buttonText}
      />
    </>
  )
}

export default CopyWeeklyReport
