import { useTranslation } from 'react-i18next'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import SecondaryButton from '../../../../../../shared/components/buttons/SecondaryButton/SecondaryButton'
import styles from './EditWeeklyDraft.module.scss'
import { TOrdersCalendarDataItem } from '../../../types'
import { useQuantityReportsContext } from '../../../state/QuantityReportsState'

type TProps = {
  item: TOrdersCalendarDataItem
}
const EditWeeklyDraft: FC<TProps> = ({ item: { weeklyOrderId } }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { formMethods } = useQuantityReportsContext()
  return (
    <SecondaryButton
      text={t('quantity.calendar.editWeeklyReport')}
      onClick={() => navigate(`weekly/${weeklyOrderId}?draft=true&buyerId=${formMethods.watch('buyer')}&sellerId=${formMethods.watch('seller')}`)}
      className={styles.buttonText}
    />
  )
}

export default EditWeeklyDraft
