import { FC } from 'react'
import styles from './QuantityRemove.module.scss'
import DeleteBinIcon from '../../../../../shared/components/icons/DeleteBinIcon'
import { useBaseQuantityFormContext } from '../../state/BaseQuantityFormState'

type TProps = {
  quantityIndex: number
}
const QuantityRemove: FC<TProps> = ({ quantityIndex }) => {
  const { remove } = useBaseQuantityFormContext()
  return (
    <div className={styles.buttonWrap}>
      <button
        type="button"
        onClick={(event) => {
          event.stopPropagation()
          remove(quantityIndex)
        }}
        className={styles.iconWrapper}
      >
        <DeleteBinIcon width={22} height={22}/>
      </button>
    </div>
  )
}

export default QuantityRemove
